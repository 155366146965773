import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Accordion, Flag, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { focusOnNextReviewChunk } from 'actions/search';
import {
  focusReviewSelector,
  focusedReviewChunkLocationSelector,
  focusedReviewIdSelector,
} from 'selectors/search';
import { appConfigSelector } from 'selectors/user';

import Header from 'components/ui/Header';
import reviewChunksCellFactory, {
  ReviewChunksLoadingCell,
} from 'components/ui/cells/ReviewChunksCell';
import PaneFields from 'components/ui/panels/PaneFields';
import SidePane from 'components/ui/panels/SidePane';

import * as svars from 'assets/style/variables';

import {
  useAnalysisTagFields,
  useReviewPaneFields,
} from '../useAnalysisFields';
import {
  NegativePoint,
  PositivePoint,
  ReviewChunksLegend,
  ReviewOriginalTexts,
} from './reviewPaneTools';

const ClickableAccordionTitle = styled(Accordion.Title)`
  &&&& {
    background: ${svars.colorWhite};
    display: flex;
    align-items: center;
    padding: 0;
    & div {
      width: 100%;
      padding: ${svars.spaceMedium} 0;
    }
    ${svars.hoverClickableCss}
    ${svars.activeClickableCss}
  }
`;

function FixedFlag({ name, ...props }) {
  // If name is ca, use the catalan flag instead of the canadian one
  const flagName = name === 'ca' ? 'catalonia' : name;
  return <Flag name={flagName} {...props} />;
}

FixedFlag.propTypes = { name: PropTypes.string.isRequired };

const getReviewTextsFields = (highlightTextField, highlightChunkIndex) => [
  {
    accessor: 'texts',
    scrollable: true,
    key: 'texts',
    Cell: reviewChunksCellFactory(
      highlightTextField === 'texts' ? highlightChunkIndex : null
    ),
    getCellProps: (review) => ({
      classifications: Object.values(review.classifications || {})?.reduce(
        (acc, item) => [...acc, ...(item?.items_texts || [])],
        []
      ),
      chunksFormElementValues: review.chunks_form_element_values,
    }),
    LoadingCell: ReviewChunksLoadingCell,
  },
  ...[
    ['positive_points', 'items_positive', PositivePoint],
    ['negative_points', 'items_negative', NegativePoint],
  ].map(([accessorKey, classificationKey, PointComponent]) => ({
    accessor: accessorKey,
    scrollable: true,
    key: accessorKey,
    Cell: reviewChunksCellFactory(
      highlightTextField === accessorKey ? highlightChunkIndex : null,
      PointComponent
    ),
    getCellProps: (review) => ({
      classifications: Object.values(review.classifications || {})?.reduce(
        (acc, item) => [...acc, ...(item?.[classificationKey] || [])],
        []
      ),
    }),
    LoadingCell: null,
  })),
];

function ReviewPane({ onClose }) {
  const dispatch = useDispatch();
  const reviewId = useSelector(focusedReviewIdSelector);
  const review = useSelector(focusReviewSelector);
  const { chunkIndex, textField } = useSelector(
    focusedReviewChunkLocationSelector
  );
  const [displayOriginalText, setDisplayOriginalText] = useState(false);
  const appConfig = useSelector(appConfigSelector);
  const tagFields = useAnalysisTagFields({ isReview: true });

  const onFocusPreviousReview = useCallback(() => {
    if (review) dispatch(focusOnNextReviewChunk(false));
  }, [review]);
  const onFocusNextReview = useCallback(() => {
    if (review) dispatch(focusOnNextReviewChunk(true));
  }, [review]);

  const columns = useReviewPaneFields();
  const reviewTextsFields = useMemo(
    () => getReviewTextsFields(textField, chunkIndex),
    [textField, chunkIndex]
  );
  const isLoading = review === undefined;
  return (
    <SidePane
      dimmed={0}
      dimmerColor={svars.colorLightestGrey}
      onToggle={onClose}
      animation="push"
      direction="right"
      visible={!!reviewId}
      width="very wide"
    >
      <SidePane.Header
        title={t`verbatim-details`}
        onToggle={onClose}
        onSelectPrevious={onFocusPreviousReview}
        onSelectNext={onFocusNextReview}
        onSelectPreviousHelp={t`previous-extract`}
        onSelectNextHelp={t`next-extract`}
        gaCategory="Campaign - feedback panel"
      />
      <SidePane.Body>
        <>
          <PaneFields
            loading={isLoading}
            fields={columns}
            reviewItem={review}
          />
          <PaneFields
            loading={isLoading}
            header={t`texts`}
            headerIcon="quote right"
            headerAction={<ReviewChunksLegend />}
            fields={reviewTextsFields}
            reviewItem={review}
          />
          {review?.form_element_values?.length ? (
            <PaneFields
              loading={isLoading}
              header={t`tag(s)`}
              headerIcon="tags"
              fields={tagFields}
              reviewItem={review}
            />
          ) : null}
          {(appConfig?.SHOW_ORIGINAL_TEXTS &&
            !isLoading &&
            Object.keys(review.original_text).length && (
              <Accordion>
                export{' '}
                <ClickableAccordionTitle
                  active={displayOriginalText}
                  index={0}
                  onClick={() => setDisplayOriginalText(!displayOriginalText)}
                >
                  <Header>
                    <Icon
                      style={{ margin: `0 ${svars.spaceNormal}` }}
                      name="dropdown"
                    />
                    <Trans id="display-original-text" />
                    <FixedFlag
                      name={review.original_text?.language}
                      style={{
                        marginRight: 0,
                        marginLeft: svars.spaceMedium,
                      }}
                    />
                  </Header>
                  export{' '}
                </ClickableAccordionTitle>
                <Accordion.Content active={displayOriginalText}>
                  <ReviewOriginalTexts
                    review={{
                      texts: review.original_text.original_texts,
                      positive_points:
                        review.original_text.original_positive_points,
                      negative_points:
                        review.original_text.original_negative_points,
                    }}
                  />
                </Accordion.Content>
              </Accordion>
            )) ||
            null}
        </>
      </SidePane.Body>
    </SidePane>
  );
}

ReviewPane.propTypes = { onClose: PropTypes.func.isRequired };

ReviewPane.defaultProps = {};

export default ReviewPane;
