import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon, Modal, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { deleteFilterPreset, selectFilterPreset } from 'actions/search';
import { formatDate } from 'reducers/locale';

import EmptyDataPage from 'components/ui/EmptyDataPage';
import { LightHeader } from 'components/ui/Header';
import { AnalyticsAwareLink } from 'components/ui/Link';
import Segment from 'components/ui/Segment';
import { ButtonDanger, ButtonTransparentAccent } from 'components/ui/button';
import { SecondaryTabButton } from 'components/ui/button/TabButton';
import { AnalyticsAwareHoverableIconButtonWithTooltip } from 'components/ui/icon/HoverableIcon';
import emptyFiltersUrl from 'components/ui/svg/undraw_circles_y7s2.svg';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

const DropdownContainer = styled.div`
  max-height: 60vh;
  min-width: 45vw;
  max-width: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding: ${svars.spaceNormal};
  padding-top: 0;
`;

const DropdownItemLabelContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const LightHeaderLabel = styled(LightHeader)`
  &&& {
    padding: 0;
    padding-right: ${svars.spaceMedium};
    margin: 0;
    margin-bottom: ${svars.spaceNormal};
    display: flex;
    align-items: center;
    height: 25px;
  }
`;
const DropdownLikeSecondaryTabButton = styled(SecondaryTabButton)`
  &&&&&& {
    width: 100%;
    border-color: ${svars.colorGreyMedium};
    ${({ opened }) =>
      opened
        ? `
        border-color: inherit;
    `
        : ''}
    color: inherit;
    &&& i {
      background: initial;
      color: initial;
    }
    &:hover {
      background: initial;
      border-color: inherit;
      &&& i {
        background: initial;
      }
    }
  }
`;

function DeletePresetConfirmModal({
  presetName,
  onClose,
  modalIsOpen,
  onConfirmDelete,
}) {
  return (
    <Modal size="small" closeIcon onClose={onClose} open={modalIsOpen}>
      <Modal.Header content={t`delete-filter-preset`} />
      <Modal.Content>
        <Trans id="filter-preset.confirm-delete" /> : {presetName}
      </Modal.Content>
      <Modal.Actions>
        <ButtonTransparentAccent onClick={onClose}>
          <Trans id="cancel" />
        </ButtonTransparentAccent>
        <ButtonDanger type="submit" onClick={onConfirmDelete}>
          <Trans id="delete" />
        </ButtonDanger>
      </Modal.Actions>
    </Modal>
  );
}
DeletePresetConfirmModal.propTypes = {
  presetName: PropTypes.string.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
};
const getPresetById = (id, presets) =>
  (id && presets.find((preset) => id === preset.id)) || {};

const HoverableSegment = styled(Segment)`
  &&& {
    cursor: pointer;
    &:hover {
      background: ${svars.accentColorLightest};
    }
  }
`;

function FilterPresetDropdown({
  viewFacetId,
  selectedPreset,
  filterPresets,
  onPresetChange,
  onToggle,
  modalIsOpen,
  onDeleteFilterPreset,
  togglePresetModal,
  onUpdatePresetModalOpen,
  onToggleFiltersPane,
}) {
  const [renamePresetId, setRenamePresetId] = useState(null);
  const [deleteConfirmModalPresetId, setDeleteConfirmModalPresetId] =
    useState(false);
  useEffect(() => {
    if (modalIsOpen === false) setRenamePresetId(null);
  }, [modalIsOpen]);
  const toDeletePreset = getPresetById(
    deleteConfirmModalPresetId,
    filterPresets
  );
  const onConfirmDelete = useCallback(
    (e) => {
      e.stopPropagation();
      onDeleteFilterPreset(deleteConfirmModalPresetId).then(() => {
        setDeleteConfirmModalPresetId(false);
        if (selectedPreset === deleteConfirmModalPresetId) {
          onPresetChange(null, { value: null });
        }
      });
    },
    [
      deleteConfirmModalPresetId,
      onDeleteFilterPreset,
      setDeleteConfirmModalPresetId,
    ]
  );
  const onPresetSelect = useCallback(
    (id) => () => {
      onPresetChange(null, { value: id });
      onToggle();
      onToggleFiltersPane();
    },
    [onPresetChange, onToggle]
  );
  const onDeletePresetIconClick = useCallback(
    (id) => (e) => {
      e.stopPropagation();
      setDeleteConfirmModalPresetId(id);
      onToggle();
    },
    [onToggle]
  );
  const onConfigurePreset = useCallback(
    (id) => (e) => {
      e.stopPropagation();
      onUpdatePresetModalOpen(id);
      onToggle();
    },
    []
  );
  const onCloseDeleteConfirmModal = useCallback(
    () => setDeleteConfirmModalPresetId(false),
    []
  );
  const selectedItem =
    (selectedPreset && filterPresets.find(({ id }) => id === selectedPreset)) ||
    {};
  return (
    <>
      <Popup
        open={modalIsOpen}
        flowing
        trigger={
          <span
            style={{
              paddingRight: svars.spaceNormalLarge,
              flexGrow: 1,
              width: '100%',
              overflowX: 'hidden',
            }}
          >
            <DropdownLikeSecondaryTabButton
              icon="chevron down"
              labelPosition="right"
              content={selectedItem.name || t`select-a-filter-preset`}
              opened={modalIsOpen ? 'true' : null}
            />
          </span>
        }
        content={
          <div
            style={{
              padding: `${svars.spaceMedium} ${svars.spaceNormalLarge}`,
            }}
          >
            {filterPresets.length ? (
              <>
                <LightHeader>
                  <Trans id="select-a-preset" /> :
                </LightHeader>
                <DropdownContainer>
                  {filterPresets.map(({ id, name, create_date }) => (
                    <HoverableSegment
                      key={`fpsi-${id}`}
                      style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                      }}
                      onClick={onPresetSelect(id)}
                    >
                      <AnalyticsAwareHoverableIconButtonWithTooltip
                        gaCategory="Dashboard - search"
                        gaAction="Delete preset"
                        help={t`delete`}
                        transparent="true"
                        danger="true"
                        name="trash"
                        onClick={onDeletePresetIconClick(id)}
                        style={{ marginRight: svars.spaceNormal }}
                      />
                      <AnalyticsAwareHoverableIconButtonWithTooltip
                        gaCategory="Dashboard - search"
                        gaAction="Configure preset"
                        help={t`configure`}
                        transparent="true"
                        accent="true"
                        name="cog"
                        onClick={onConfigurePreset(id)}
                        style={{ marginRight: svars.spaceMedium }}
                      />
                      <DropdownItemLabelContainer>
                        <LightHeaderLabel inline="true">
                          {id === renamePresetId ? (
                            <div />
                          ) : (
                            <span
                              style={{
                                whiteSpace: 'pre',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {name}
                            </span>
                          )}
                        </LightHeaderLabel>
                        <div>
                          <Trans id="created-on@fem." />
                          {'  '}
                          {formatDate(new Date(create_date), 'PP')}
                        </div>
                      </DropdownItemLabelContainer>
                      <Icon
                        color="blue"
                        size="large"
                        name="arrow alternate circle right outline"
                      />
                    </HoverableSegment>
                  ))}
                </DropdownContainer>
              </>
            ) : (
              <EmptyDataPage
                illustrationUrl={emptyFiltersUrl}
                maxHeight="8rem"
                headerText={<Trans id="no-preset-defined-for-facet" />}
                actionComponent={
                  <div>
                    <Trans>
                      <AnalyticsAwareLink
                        gaCategory="Dashboard - search"
                        gaAction="Create preset"
                        gaLabel={`facet=${viewFacetId}`}
                        base="true"
                        onClick={togglePresetModal}
                      >
                        Create a new preset
                      </AnalyticsAwareLink>{' '}
                      to save your filters, optionnally add a notification.
                    </Trans>
                  </div>
                }
              />
            )}
          </div>
        }
        on="click"
        onClose={onToggle}
        onOpen={onToggle}
        position="bottom right"
        style={{ zIndex: 900, padding: 0 }}
      />
      {deleteConfirmModalPresetId ? (
        <DeletePresetConfirmModal
          presetName={toDeletePreset.name}
          onClose={onCloseDeleteConfirmModal}
          modalIsOpen
          onConfirmDelete={onConfirmDelete}
        />
      ) : null}
    </>
  );
}

FilterPresetDropdown.propTypes = {
  viewFacetId: PropTypes.string.isRequired,
  filterPresets: commonPropTypes.filterPresets.isRequired,
  onPresetChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  onDeleteFilterPreset: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  selectedPreset: PropTypes.string,
  togglePresetModal: PropTypes.func.isRequired,
  onUpdatePresetModalOpen: PropTypes.func.isRequired,
  onToggleFiltersPane: PropTypes.func.isRequired,
};

FilterPresetDropdown.defaultProps = { selectedPreset: null };

export default connect(null, {
  onDeleteFilterPreset: deleteFilterPreset,
  onPresetChange: selectFilterPreset,
})(FilterPresetDropdown);
