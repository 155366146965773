import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import getAnalysisSearchDataToExport from 'actions/getAnalysisSearchDataToExport';
import {
  focusReviewChunk,
  maybeUpdateSearchFilters,
  paginateReviewChunks,
  resetAllFilters,
  searchLoadingSelector,
  switchSearchDateSort,
  updateSearchFilters,
} from 'actions/search';
import { useConceptLabelFormatter } from 'reducers/entityLabelFormatter';
import { facetEntitiesLoadingSelector } from 'selectors/entities';
import {
  defaultExportFilenameSelector,
  focusedReviewChunkSelector,
  nFiltersPositiveSelector,
  nMaxChunksSelector,
  searchResultsSelector,
  sortDateAscSelector,
} from 'selectors/search';
import { appConfigSelector } from 'selectors/user';
import { viewFacetSelector } from 'selectors/view';

import ReviewPane from 'components/customer/analysis/search/ReviewPane';
import { SecondaryButtonWithDeleteLabel } from 'components/ui/button';
import SearchExportButtonWithModal from 'components/ui/modal/SearchExportModal';
import { AnalysisSearchPagination } from 'components/ui/search/SearchPagination';
import ReactTable from 'components/ui/table/ReactTableUi';
import Statistics from 'components/ui/visualization/Statistics';

import { polarityFormatter } from 'utils/formatter';
import { capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';

import useAnalysisColumns, { useAnalysisTagFields } from '../useAnalysisFields';
import FiltersDeleteGroup from './FiltersDeleteGroup';
import SearchFiltersPane from './SearchFiltersPane';

const EXPORT_EXCLUDE_COLUMN_IDS = ['sentiment'];

const ResultsContainer = styled.div`
  width: 100%;
  padding: 0 ${svars.spaceNormal};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const SearchHeader = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin: ${svars.spaceNormal} 0;
`;
const SearchTableContainer = styled.div`
  min-height: 80vh;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
function ChunkSearch({ facetEntitiesLoading, loading, onSwitchSearchSort }) {
  const dispatch = useDispatch();
  const [filtersAreOpen, setFiltersAreOpen] = useState(false);

  const appConfig = useSelector(appConfigSelector);
  const viewFacet = useSelector(viewFacetSelector);
  const searchResults = useSelector(searchResultsSelector);
  const sortDateAsc = useSelector(sortDateAscSelector, shallowEqual);
  const defaultFilename = useSelector(defaultExportFilenameSelector);
  const nMaxChunks = useSelector(nMaxChunksSelector);
  const conceptLabelFormatter = useConceptLabelFormatter();

  const columns = useAnalysisColumns({
    sortDateAsc,
    onSwitchSearchSort,
  });
  const extraColumns = useMemo(
    () => [
      {
        id: 'concepts',
        accessor: (item) => item.concepts,
        formatter: (concepts) =>
          concepts?.map(conceptLabelFormatter)?.join(', '),
        label: t`concepts`,
      },
      {
        id: 'polarity',
        accessor: (item) => item.polarity,
        formatter: polarityFormatter,
        label: t`polarity`,
      },
    ],
    [conceptLabelFormatter]
  );
  const tagFields = useAnalysisTagFields();
  const nFiltersPositive = useSelector(nFiltersPositiveSelector);
  const selectedRow = useSelector(focusedReviewChunkSelector);

  const onRowClick = useCallback((rowInfo, handleOriginal) => {
    // Find index in original (unfiltered) treemap array
    // NB : the header is at index 0
    if (rowInfo && rowInfo.review_id !== undefined) {
      dispatch(focusReviewChunk(rowInfo));
    }
    if (handleOriginal) {
      handleOriginal();
    }
  }, []);
  const onSortedChange = useCallback(() => {
    dispatch(updateSearchFilters({ sortDateAsc: !sortDateAsc }));
  }, [sortDateAsc]);
  const onPageChange = useCallback(() => {
    dispatch(paginateReviewChunks());
  }, []);
  const onCloseReviewModal = useCallback(
    () => dispatch(focusReviewChunk(undefined)),
    []
  );
  const onToggleFilters = useCallback(() => {
    if (!filtersAreOpen) {
      onCloseReviewModal();
    } else {
      dispatch(maybeUpdateSearchFilters({}));
    }

    setFiltersAreOpen(!filtersAreOpen);
  }, [filtersAreOpen]);

  const resetFilters = useCallback((event) => {
    event.stopPropagation();
    dispatch(resetAllFilters());
  }, []);
  useEffect(
    // Close panel when component is destroyed
    () => () => {
      onCloseReviewModal();
    },
    []
  );
  return (
    <div
      style={{
        display: 'flex',
        minHeight: 0,
        width: '100%',
      }}
    >
      <ReviewPane onClose={onCloseReviewModal} />
      <SearchFiltersPane
        visible={filtersAreOpen}
        onToggle={onToggleFilters}
        viewFacet={viewFacet}
        facetEntitiesLoading={facetEntitiesLoading}
      />
      <div style={{ display: 'flex', width: '100%' }}>
        <ResultsContainer>
          <SearchHeader>
            <span
              id="search-header"
              style={{ display: 'inline-flex', overflow: 'hidden' }}
            >
              <SecondaryButtonWithDeleteLabel
                onClick={onToggleFilters}
                onLabelClick={resetFilters}
                loading={loading}
                showLabel={!!nFiltersPositive}
              />
              <FiltersDeleteGroup disabled={loading} tagFields={tagFields} />
            </span>
            <span
              style={{ display: 'inline-flex', justifyContent: 'flex-end' }}
            >
              <Statistics
                style={{ margin: `0 ${svars.spaceNormal}` }}
                disabled={loading}
                nChunks={searchResults?.statistics?.n_chunks}
                nMaxChunks={appConfig?.STATISTICS_MAX_CHUNKS}
                nChunksLabel={t`Extracts`}
                averageSentiment={searchResults?.statistics?.average_sentiment}
              />
            </span>
          </SearchHeader>
          <SearchTableContainer>
            <ReactTable
              style={{ overflow: 'hidden' }}
              noDataText={loading ? '' : t`no-result`}
              loadingText={`${capitalize(t`loading`)} ...`}
              loading={loading}
              sortable={false}
              selectedRow={selectedRow}
              // By setting review_id as rowKey, we highlight all chunks of the same review
              // when one chunk is selected
              rowKey="id"
              data={searchResults.reviewChunks}
              columns={columns}
              onSortedChange={onSortedChange}
              onRowClick={onRowClick}
              scrollOnSelect
            />
            <AnalysisSearchPagination
              nResults={searchResults?.statistics?.n_chunks}
              onPageChange={onPageChange}
              loading={loading}
              moreResultsMessage={capitalize(t`load-more-extracts`)}
              nMaxChunks={appConfig?.STATISTICS_MAX_CHUNKS}
            >
              <SearchExportButtonWithModal
                extraColumns={extraColumns}
                style={{ marginRight: svars.spaceNormal }}
                disabled={loading || !searchResults?.reviewChunks?.length}
                columns={columns}
                excludeIds={EXPORT_EXCLUDE_COLUMN_IDS}
                defaultFilename={defaultFilename}
                nMaxChunks={nMaxChunks}
                nChunksLimit={appConfig?.n_chunks_export_limit}
                getRawDataAction={getAnalysisSearchDataToExport}
              />
            </AnalysisSearchPagination>
          </SearchTableContainer>
        </ResultsContainer>
      </div>
    </div>
  );
}

ChunkSearch.propTypes = {
  onSwitchSearchSort: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  facetEntitiesLoading: PropTypes.bool.isRequired,
};

ChunkSearch.defaultProps = {
  loading: true,
};

export default connect(
  (state) => ({
    loading: searchLoadingSelector(state),
    facetEntitiesLoading: facetEntitiesLoadingSelector(state),
  }),
  {
    onSwitchSearchSort: switchSearchDateSort,
  }
)(ChunkSearch);
