import { useCallback, useMemo } from 'react';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import ExportAsXlsButton from 'components/ui/button/export-as/ExportAsXls';
import { TextInput } from 'components/ui/inputs/TextInput';
import useTableFilter from 'components/ui/search/useTableFilter';
import { useReactTableColumns } from 'components/ui/table/ReactTableColumns';
import ReactTableHeader from 'components/ui/table/ReactTableHeader';
import StyledReactTable from 'components/ui/table/ReactTableUi';

import { roundWithPrecision } from 'utils/formatter';
import { capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';

function DifferentialSentimentHeader() {
  return (
    <ReactTableHeader
      title={t`sentiment-compared-to-mean`}
      helpMessage={
        <Trans id="help-message.sentiment-compared-to-mean-description" />
      }
    />
  );
}

const DEFAULT_SORTED = [{ id: 'n_chunks', desc: true }];
const getExportColumns = (
  categoryDataKey,
  categoryHeader,
  labelFormatter,
  globalAverageSentiment,
  withShareOfExtracts
) => [
  {
    key: categoryDataKey,
    label: categoryHeader,
    formatter: labelFormatter,
    width: 50,
  },
  { key: 'n_chunks', label: capitalize(t`volume`) },
  ...(withShareOfExtracts
    ? [
        {
          key: 'share_of_extracts',
          label: capitalize(t`share-of-extracts`),
          formatter: roundWithPrecision,
        },
      ]
    : []),
  {
    key: 'average_sentiment',
    label: capitalize(t`sentiment`),
    formatter: roundWithPrecision,
  },
  {
    key: 'average_sentiment',
    label: capitalize(t`differential-sentiment`),
    formatter: (value) => roundWithPrecision(value - globalAverageSentiment),
    width: 20,
  },
];

const DefinitionContainer = styled.i`
  display: flex;
  padding: ${svars.spaceMedium} ${svars.spaceSmall} ${svars.spaceSmall}
    ${svars.spaceSmall};
  background-color: ${svars.colorGreyMedium};
  font-size: ${svars.fontSizeSmall};
  line-height: 1rem;
`;

function ExplorationBreakdownTable({
  categoryDataKey,
  categoryHeader,
  displayLeafConceptsOnly,
  legend,
  labelFormatter,
  data,
  globalAverageSentiment,
  exportName,
  withShareOfExtracts,
}) {
  const labelAccessor = useCallback(
    (item) => labelFormatter(item[categoryDataKey]),
    [labelFormatter]
  );
  const [filteredData, textFilterValue, filterRows] = useTableFilter(
    data,
    labelAccessor
  );
  const columns = useMemo(() => {
    const maxNChunks = data ? Math.max(...data.map((row) => row.n_chunks)) : 0;
    return useReactTableColumns(
      {
        product_hierarchy: {
          accessor: labelAccessor,
          Header: capitalize(categoryHeader),
        },
        n_chunks: { id: 'n_chunks' },
        ...(withShareOfExtracts
          ? { share_of_extracts: { id: 'share_of_extracts' } }
          : {}),
        average_sentiment: { id: 'average_sentiment' },
        differential_sentiment: {
          id: 'differential_sentiment',
          Header: DifferentialSentimentHeader,
          minWidth: 150,
        },
      },
      maxNChunks,
      globalAverageSentiment
    );
  }, [data, categoryHeader, labelAccessor]);
  const exportColumns = useMemo(
    () =>
      getExportColumns(
        categoryDataKey,
        categoryHeader,
        labelFormatter,
        globalAverageSentiment,
        withShareOfExtracts
      ),
    [
      categoryDataKey,
      categoryHeader,
      labelFormatter,
      globalAverageSentiment,
      withShareOfExtracts,
    ]
  );
  return (
    <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
      <div
        style={{
          paddingBottom: svars.spaceNormal,
          width: '100%',
          flexShrink: 0,
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            padding: svars.spaceNormal,
            float: 'right',
          }}
        >
          {data?.length > 10 ? (
            <TextInput
              style={{
                maxWidth: '500px',
                flexGrow: 1,
                marginRight: svars.spaceMediumLarge,
              }}
              icon="search"
              placeholder={capitalize(t`search-an-element`)}
              value={textFilterValue}
              onChange={filterRows}
            />
          ) : (
            // Required to keep positioning of export button as we use 'space-between' rule
            <div />
          )}
          <ExportAsXlsButton
            exportName={exportName}
            data={data}
            disabled={!data?.length}
            columns={exportColumns}
          />
        </div>
      </div>
      {/* <div style={{ minHeight: '85%' }}> */}
      <div
        style={{
          marginBottom: svars.spaceMedium,
          display: 'flex',
          overflow: 'hidden',
        }}
      >
        {data?.length && (
          <StyledReactTable
            defaultSorted={DEFAULT_SORTED}
            key={`treemap-table-leafonly-${displayLeafConceptsOnly}`}
            data={filteredData}
            columns={columns}
            i18nNoDataText={t`no-element-found`}
            rowKey={categoryDataKey}
          />
        )}
      </div>
      {/* </div> */}
      {legend.length ? (
        <DefinitionContainer>
          <div>
            {legend.map((item) => (
              <div
                key={`help-${item.slice(0, 20)}`}
                style={{ paddingBottom: '4px' }}
              >
                <Icon name="attention" />{' '}
                <span key={`definition-${item}`}>{item}</span>
              </div>
            ))}
          </div>
        </DefinitionContainer>
      ) : null}
    </div>
  );
}

ExplorationBreakdownTable.propTypes = {
  categoryDataKey: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      n_chunks: PropTypes.number,
      label: PropTypes.string,
      average_sentiment: PropTypes.number,
      parent_id: PropTypes.string,
    })
  ),
  legend: PropTypes.arrayOf(PropTypes.string),
  displayLeafConceptsOnly: PropTypes.bool,
  labelFormatter: PropTypes.func.isRequired,
  globalAverageSentiment: PropTypes.number,
  exportName: PropTypes.string.isRequired,
  categoryHeader: PropTypes.string,
  // Wether to display the share of extracts in the table
  withShareOfExtracts: PropTypes.bool,
};
ExplorationBreakdownTable.defaultProps = {
  data: [],
  legend: [],
  displayLeafConceptsOnly: true,
  globalAverageSentiment: undefined,
  categoryHeader: null,
  withShareOfExtracts: false,
};

export default ExplorationBreakdownTable;
